<template>
    <div class="pa-5">
        <div class="mb-5">
            <v-btn small color="primary" @click="createGroup()" v-if="user.account_type == 'admin'">Create a new group</v-btn>
        </div>
        <v-row>
            <v-col cols="12" sm="6" md="4" v-for="g in file_groups" :key="g.id">
                <v-card color="primary">
                    <v-card-title class="white--text">
                        {{ g.name }}
                    </v-card-title>
                    <v-card-subtitle class="white--text">
                        {{ g.description }}
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-list dense>
                        <v-list-item v-for="f in g.files" :key="f.id">
                            <v-list-item-title>{{ f.name }}</v-list-item-title>
                            <v-list-item-icon @click="viewFileLink(f)">
                                <v-icon small>mdi-link</v-icon>
                            </v-list-item-icon>
                            <v-list-item-icon @click="editFileRecord(f)" v-if="user.account_type == 'admin'">
                                <v-icon small>mdi-pencil-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-icon @click="deleteFileRecord(f)" v-if="user.account_type == 'admin'" class="ml-0">
                                <v-icon small>mdi-close-circle</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                        
                        <v-divider v-if="user.account_type == 'admin'"></v-divider>
                        <v-list-item v-if="user.account_type == 'admin'">
                            <v-btn small @click="addFileRecord(g.id)" block color="primary">add New</v-btn>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog width="500" v-model="view_group_details">
            <v-card>
                <v-card-title>File Group/Category Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-text-field label="Group Name" v-model="file_group.name"></v-text-field>
                    <v-textarea rows="3" label="Description" v-model="file_group.description"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="saveGroup()">Save Group</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="view_file_details">
            <v-card>
                <v-card-title>File Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="pt-5">
                        <v-text-field outlined dense label="Name" v-model="file.name"></v-text-field>
                        <v-text-field outlined dense label="File Link" v-model="file.file_link"></v-text-field>
                        <v-textarea rows="2" outlined dense label="Description" v-model="file.description"></v-textarea>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small block color="primary" @click="saveFileRecord()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'Dashboard',
    data() {
        return {
            search : null,
            view_file_details : false,
            view_group_details : false,
            files : [],
            file : {},
            file_groups : [],
            file_group : {},
            user : {}
        }
    },
    mounted() {
        this.user = JSON.parse(this.$store.state.user);
        console.log(this.user);
        this.refreshFiles();
    },
    methods : {
        refreshFiles() {
            axios({
                url : process.env.VUE_APP_API_URL + '/file-directory/file-groups',
                method : 'GET',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.file_groups = output.data;
            })
        },
        createGroup() {
            this.file_group = {};
            this.view_group_details = true;
        },
        saveGroup(){
            axios({
                url : process.env.VUE_APP_API_URL + '/file-directory/file-groups',
                method : 'POST',
                data : this.file_group,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.view_group_details = false;
                this.refreshFiles();
            })
        },
        addFileRecord(id) {
            this.file = {
                group_id : id
            };
            this.view_file_details = true;
        },
        viewFileLink(file) {
            window.open(file.file_link, "blank");
        },
        editFileRecord(file) {
            this.file = Object.assign({}, file);
            this.view_file_details = true;
        },
        saveFileRecord() {
            if(this.file.id == undefined) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files',
                    method : 'POST',
                    data : this.file,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshFiles();
                    this.view_file_details = false;
                }).catch(err => {
                    if(err.response.status == 400) {
                        alert(err.response.data.message);
                    }
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files/' + this.file.id,
                    method : 'PUT',
                    data : this.file,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshFiles();
                    this.view_file_details = false;
                }).catch(err => {
                    if(err.response.status == 400) {
                        alert(err.response.data.message);
                    }
                });
            }
        },
        deleteFileRecord(file) {
            if(confirm("Are you sure?")) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files/' + file.id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.refreshFiles();
                })
            }
        }
    }
}
</script>