<template>
    <div>
        <v-card>
            <v-card-text class="grey lighten-2">
                <strong>USER DETAILS</strong>
            </v-card-text>
            <v-card-text>
                <v-alert type="error" v-if="errors.length > 0">
                    <div v-for="e in errors" :key="e">{{ e }}</div>
                </v-alert>
                <div class="mb-4">
                    <v-text-field outlined label="Name" v-model="user.name" dense hide-details></v-text-field>
                </div>
                <div class="mb-4">
                    <v-text-field outlined label="Email" type="email" v-model="user.email" dense hide-details></v-text-field>
                </div>
                <div class="mb-4">
                    <v-select
                        outlined
                        dense hide-details
                        :items="['admin', 'supervisor', 'staff']"
                        v-model="user.account_type"
                        label="Account Type"
                    ></v-select>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn small color="default" @click="cancel()">
                    cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="saveUser()">
                    save
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'UserDetails',
    props : ['user', 'type'],
    data() {
        return {
            errors : []
        }
    },
    methods: {
        cancel() {
            this.$emit('close');
        },
        saveUser() {
            if(this.type == 'add') {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/users',
                    method : 'POST',
                    data : this.user,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$emit('appendUser', res.data);
                }).catch(err => {
                    this.errors = err.response.data.errors;
                });
            }
            else if(this.type == 'edit') {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/users/' + this.user.id,
                    method : 'PUT',
                    data : this.user,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$emit('refreshUsers', res.data);
                }).catch(err => {
                    this.errors = err.response.data.errors;
                });
            }
        }
    }
}
</script>