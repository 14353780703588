<template>
    <div>
        <v-row class="secondary" fixed no-gutters>
            <v-col :style="{'text-align' : 'right'}">
                <div class="pa-2">
                    <v-btn color="primary" small @click="show_status = true">{{ job.job_status }} </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="9">
                <v-tabs v-model="tab" color="primary">
                    <v-tab key="tab1">
                        Job Details
                    </v-tab>
                    <v-tab key="tab3" v-if="['started', 'paused', 'resumed', 'finished', 'submitted for checking', 'checking started', 'completed', 'for revision'].indexOf(job.job_status) != -1">
                        Job Submission Section
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="tab1" class="pa-3">
                        <v-row v-if="job.staff">
                            <v-col>
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <v-row>
                                            <v-col>
                                                <strong>ASSIGNEE</strong>
                                            </v-col>
                                            <v-col class="text-right">
                                                <v-btn x-small color="default" @click="reAssignJob()">Change</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-icon
                                                    class="grey lighten-1"
                                                    dark
                                                >
                                                    mdi-account
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="job.staff.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="job.staff.email"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <v-row>
                                            <v-col>
                                                <strong>CHECKER</strong>
                                            </v-col>
                                            <v-col class="text-right">
                                                <v-btn x-small color="default" @click="editChecker()">Change</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-icon
                                                    class="grey lighten-1"
                                                    dark
                                                >
                                                    mdi-account-check
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ job.validator ? job.validator.name : 'To be assigned' }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-text="job.validator ? job.validator.email : 'N/A'"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card class="mt-5">
                            <v-card-text class="grey lighten-3 pa-2">
                                <v-row no-gutters>
                                    <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                                        <strong> JOB DETAILS</strong>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field label="Luntian Ref #" outlined dense v-model="job.lbs_ref_no" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Client Ref #" outlined dense v-model="job.client_ref_no" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="NCC Compliance" outlined dense v-model="job.version" readonly hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field label="Submitted By" readonly outlined dense v-model="job.client.name" hide-details></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Account Client" readonly outlined dense v-model="job.company" hide-details></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Submission Date" outlined dense v-model="job.date_submitted" readonly hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-5">
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>JOB DETAILS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="job.job_address" 
                                            outlined label="Job Address" 
                                            dense 
                                            readonly
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="8" sm="12">
                                        <v-text-field 
                                        label="Job Type" 
                                        readonly 
                                        outlined 
                                        dense 
                                        :value="job.job_type"
                                        hide-details>
                                    </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <v-text-field 
                                            label="Priority" 
                                            readonly 
                                            outlined 
                                            dense 
                                            v-model="job.priority"
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea label="Notes" dense outlined v-model="job.notes" readonly hide-details></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-row class="mt-2">
                            <v-col>
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <strong>PLANS</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-if="job.plans.length == 0">
                                                    <td colspan="2">No records found</td>
                                                </tr>
                                                <tr
                                                    v-for="p, idx in job.plans"
                                                    :key="p.id"
                                                >
                                                    <td class="text-blue pointer" @click="openLink('plans', p.job_id, p.type, p.filename)">
                                                        {{ p.filename }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <strong>DOCUMENTS</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-if="job.documents.length == 0">
                                                    <td colspan="2">No records found</td>
                                                </tr>
                                                <tr
                                                    v-for="d, idx in job.documents"
                                                    :key="d.id"
                                                >
                                                    <td class="text-blue pointer" @click="openLink('documents', d.job_id, d.type, d.filename)">
                                                        {{ d.filename }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="tab3" class="pa-3">
                        <v-row>
                            <v-col>
                                <v-card :disabled="['started', 'resumed', 'for revision'].indexOf(job.job_status) == -1">
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <strong>DETAILS</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                    v-model="job.region_sz"
                                                    :items="regions"
                                                    item-text="value"
                                                    item-value="id"
                                                    label="Region - CZ"
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="job.floor_basis"
                                                    :items="['Slab on Ground', 'Suspended Floor']"
                                                    label="Floor Basis"
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-text-field 
                                                    label="Base Heating Load (Mj/M2)" 
                                                    outlined 
                                                    dense 
                                                    v-model="job.base_heating_load"
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field 
                                                    label="Base Cooling Load (Mj/M2)" 
                                                    outlined 
                                                    dense 
                                                    v-model="job.base_cooling_load"
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-textarea 
                                                    hide-details
                                                    label="Run Notes"
                                                    v-model="job.run_notes"
                                                    outlined
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <div>
                                                    <label for="">Plan Complexity</label>
                                                </div>
                                                <v-rating
                                                    v-model="job.plan_complexity"
                                                    color="yellow darken-3"
                                                    background-color="grey darken-1"
                                                    hover
                                                    large
                                                    length="3"
                                                    >
                                                </v-rating>
                                                <div>
                                                    <small>1 star = Hard to model 3 star = Easy to model</small>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <strong>FR5 FILES</strong>
                                            </v-col>
                                            <v-col class="text-right" cols="12" md="8"
                                                v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <div v-if="new_frate">
                                                    <v-btn small color="primary" class="mr-2 white--text" @click="saveFrate()">
                                                        <v-icon>mdi-floppy</v-icon> Save
                                                    </v-btn>
                                                    <v-btn small color="red darken-2 white--text" @click="new_frate = null;"> 
                                                        <v-icon>mdi-undo</v-icon> Cancel
                                                    </v-btn>
                                                </div>
                                                <div v-if="!new_frate">
                                                    <v-btn small color="grey darken-3 mr-2 white--text" @click="addFrate('file')">
                                                        <v-icon>mdi-file-plus</v-icon> file
                                                    </v-btn>
                                                    <v-btn small color="orange" class="darken-2 white--text" @click="addFrate('link')"> 
                                                        <v-icon>mdi-link</v-icon> URL
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="new_frate">
                                        <div>
                                            <v-file-input
                                                show-size
                                                dense
                                                hide-details
                                                label="FR5 File"
                                                outlined
                                                v-model="new_frate.file"
                                                v-if="new_frate.type == 'file'"
                                            ></v-file-input>
                                            <v-text-field
                                                show-size
                                                dense
                                                hide-details
                                                outlined
                                                label="FR5 File Url"
                                                v-model="new_frate.file"
                                                v-if="new_frate.type == 'link'"
                                            ></v-text-field>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <tr>
                                                    <th class="text-left">
                                                        File
                                                    </th>
                                                    <th class="text-left" style="width: 80px;">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="job.frates.length == 0">
                                                    <td colspan="2">No records found</td>
                                                </tr>
                                                <tr v-for="f, idx in job.frates" :key="f.id">
                                                    <td 
                                                        class="text-blue pointer"
                                                        @click="openLink('frates', f.job_id, f.type, f.filename)">
                                                        {{ f.filename }}
                                                    </td>
                                                    <td  v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                        <v-btn small icon color="red" @click="deleteFrate(idx, f.id)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <strong>DB FILES</strong>
                                            </v-col>
                                            <v-col class="text-right" cols="12" md="8"  
                                                v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <div v-if="!new_db">
                                                    <v-btn small color="grey darken-3 mr-2 white--text" @click="addDB('file')">
                                                        <v-icon>mdi-file-plus</v-icon> FILE
                                                    </v-btn>
                                                    <v-btn small color="orange" class="darken-2 white--text" @click="addDB('link')"> 
                                                        <v-icon>mdi-link</v-icon> URL
                                                    </v-btn>
                                                </div>
                                                <div v-if="new_db">
                                                    <v-btn small color="primary mr-2 white--text" @click="saveDB()">
                                                        <v-icon>mdi-floppy</v-icon> Save
                                                    </v-btn>
                                                    <v-btn small color="red darken-2 white--text" @click="new_db = null;"> 
                                                        <v-icon>mdi-undo</v-icon> Cancel
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="new_db">
                                        <div>
                                            <v-file-input
                                                show-size
                                                dense
                                                hide-details
                                                outlined
                                                label="DB File"
                                                v-model="new_db.file"
                                                v-if="new_db.type == 'file'"
                                            ></v-file-input>
                                            <v-text-field
                                                show-size
                                                dense
                                                hide-details
                                                outlined
                                                label="DB File Url"
                                                v-model="new_db.file"
                                                v-if="new_db.type == 'link'"
                                            ></v-text-field>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <tr>
                                                    <th class="text-left">
                                                        File
                                                    </th>
                                                    <th class="text-left" style="width: 80px;">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="job.dbs.length == 0">
                                                    <td colspan="2">No records found</td>
                                                </tr>
                                                <tr v-for="d, idx in job.dbs" :key="d.id">
                                                    <td class="text-blue pointer"
                                                        @click="openLink('dbs', d.job_id, d.type, d.filename)">
                                                        {{ d.filename }}
                                                    </td>
                                                    <td v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                        <v-btn small icon color="red" @click="deleteDB(idx, d.id)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card>
                                    <v-card-text class="grey lighten-3 pa-2">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <strong>PRIVATE FILES</strong>
                                            </v-col>
                                            <v-col class="text-right" cols="12" md="8" 
                                                v-if="job.job_status != 'completed'">
                                                <div v-if="!new_private_file">
                                                    <v-btn small color="grey darken-3 mr-2 white--text" @click="addPrivateFile('file')">
                                                        <v-icon>mdi-file-plus</v-icon> FILE
                                                    </v-btn>
                                                    <v-btn small color="orange" class="darken-2 white--text" @click="addPrivateFile('link')"> 
                                                        <v-icon>mdi-link</v-icon> URL
                                                    </v-btn>
                                                </div>
                                                <div v-if="new_private_file">
                                                    <v-btn small color="primary mr-2 white--text" @click="savePrivateFile()">
                                                        <v-icon>mdi-floppy</v-icon> Save
                                                    </v-btn>
                                                    <v-btn small color="red darken-2 white--text" @click="new_private_file = null;"> 
                                                        <v-icon>mdi-undo</v-icon> Cancel
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="new_private_file">
                                        <div>
                                            <v-file-input
                                                show-size
                                                dense
                                                hide-details
                                                outlined
                                                label="Private File"
                                                v-model="new_private_file.file"
                                                v-if="new_private_file.type == 'file'"
                                            ></v-file-input>
                                            <v-text-field
                                                show-size
                                                dense
                                                hide-details
                                                outlined
                                                label="Private File Url"
                                                v-model="new_private_file.file"
                                                v-if="new_private_file.type == 'link'"
                                            ></v-text-field>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        File
                                                    </th>
                                                    <th class="text-left" style="width: 80px;">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="d, idx in job.private_files" :key="d.id">
                                                    <td class="text-blue pointer"
                                                        @click="openLink('private-files', d.job_id, d.type, d.filename)">
                                                        {{ d.filename }}
                                                    </td>
                                                    <td>
                                                        <v-btn small icon color="red" @click="deletePrivateFile(idx, d.id)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="job.job_status == 'for revision'">
                            <v-col>
                                <v-card>
                                    <v-card-text class="text-uppercase">
                                        <strong>{{ job.validator.name }}'S REMARKS</strong>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <div v-html="job.checker_remarks"></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="3">
                <div>
                    <div class="mb-5">
                        <v-btn x-small 
                            @click="active_section = 'conversation'" class="mr-2"
                            :color="active_section == 'conversation' ? 'primary' : 'default'">comments</v-btn>
                        <v-btn x-small 
                            @click="active_section = 'timeline'"
                            :color="active_section == 'timeline' ? 'primary' : 'default'">timeline</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <div class="comment-section" v-if="active_section == 'conversation'">
                        <v-btn small text block @click="comment_dialog = true;">Write a comment</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <div class="comment-trail" v-if="active_section == 'conversation'">
                        <div class="comment-trail-item" v-for="comment in job.comments.slice(offset, 5*page)" v-bind:key="comment.id">
                            <div class="comment-avatar-cont">
                                <v-avatar
                                :color="comment.account_type == 'client' ? 'red' : 'primary'"
                                size="40"
                                >
                                <span class="white--text text-h5">{{ comment.name.substring(0,1) }}</span>
                                </v-avatar>
                            </div>
                            <div class="comment-content-cont">
                                <div class="author">{{ comment.name }}</div>
                                <div class="date">{{ comment.created_at }}</div>
                                <div class="content" v-html="comment.message"></div>
                            </div>
                        </div>
                        <div class="text-center" v-if="job.comments.lenth > 5">
                            <v-divider></v-divider>

                            <div class="mt-2">
                                <v-btn small 
                                    class="mr-2"
                                    :disabled="page == 1"
                                    @click="paginate(-1)" 
                                    >Prev</v-btn>
                                <v-btn small 
                                    :disabled="page >= total_pages"
                                    @click="paginate(1)"
                                    >Next
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-list dense v-if="active_section == 'timeline'">
                        <v-list-item two-line v-for="t in job.timeline" v-bind:key="t.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ t.timestamp }}</v-list-item-title>
                                <v-list-item-subtitle>{{ t.description }} by {{ t.user.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="show_checkers" width="500">
            <v-card :disabled="$store.state.loading">
                <checker-list :staffs="staffs" @assignChecker="assignChecker">
                    <template v-slot:subtitle>
                        <v-subheader>Select A Validator/Checker</v-subheader>
                    </template>
                </checker-list>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="show_staffs">
            <v-card>
                <staffs-list :staffs="staffs" @assignStaff="assign">
                    <template v-slot:subtitle>
                        <v-subheader v-if="job.job_status == 'allocated'">ASSIGN JOB {{ job.lbs_ref_no }}.</v-subheader>
                        <v-subheader v-if="job.job_status != 'allocated'">RE-ASSIGN JOB {{ job.lbs_ref_no }}. (Currently assigned to {{ job.staff.name }})</v-subheader>
                    </template>
                </staffs-list>
            </v-card>
        </v-dialog>
        <v-dialog v-model="comment_dialog" width="500">
            <v-card>
                <v-card-title>
                    Write your comment
                </v-card-title>
                <editor :content.sync="comment" @update="updateComment"></editor>
                <v-card-actions>
                    <v-btn small color="primary" block @click="postComment()" :disabled="$store.state.loading">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="show_status">
            <v-card>
                <v-card-title>Job Status</v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-for="s in job_status_list" :key="s.value" @click="updateStatus(s.value)">
                        <v-list-item-icon>
                            <v-icon :color="job.job_status == s.value ? 'primary' : 'grey'">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="job.job_status != s.value ? 'grey--text' : ''">{{ s.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .pointer {
        cursor: pointer;
    }
    .text-blue {
        color : blue;
    }
    .comment-trail {
        padding : 10px 0;
    }
    .comment-trail-item {
        display: flex;
    }
    .comment-trail-item .author {
        font-weight : bold;
    }
    .comment-trail-item .date {
        font-size: 12px;
    }
    .comment-trail-item .content {
        font-size: 13px;
    }
    .comment-content-cont {
        padding : 0px 15px;
    }
</style>
<script>
import axios from 'axios';
import { globalMixins } from '@/mixins/globalMixins';
import CheckerList from '@/components/CheckerList.vue';
import StaffsList from '@/components/StaffsList.vue';
import Editor from '@/components/Editor.vue';
export default 
{
    name : 'JobDetails',
    mixins : [globalMixins],
    components : {
        CheckerList,
        StaffsList,
        Editor
    },
    watch: {
        'job.comments'(newVal) {
            this.total_pages = Math.ceil(newVal.length / 5);
            console.log(this.total_pages);
        }
    },
    data() {
        return {
            tab : 'tab1',
            new_frate : null,
            new_db : null,
            new_private_file : null,
            job : {
                client : {}
            },
            show_checkers : false,
            staffs : [],
            validator : null,
            user : {},
            show_staffs : false,
            comment_dialog: false,
            comment : null,
            active_section : 'conversation',
            page : 1,
            offset : 0,
            total_pages : 0,
            job_status_list : [],
            show_status : false
        }
    },
    mounted() {
        this.job_status_list = this.lbs_status;
        this.$store.dispatch('loading', false);
        this.user = JSON.parse(this.$store.state.user);
        this.reload();
    },
    methods : {
        reload() {
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id,
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                if(this.job.job_status == 'submitted for checking' && this.job.validator.id == this.$store.getters.getUser.id) {
                    this.$router.push({name : 'JobDetailsCheck'});
                }
            });
        },
        updateStatus(status) {
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs/'+ this.job.id +'/status',
                method : 'PUT',
                data : {job_status : status},
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job = this.reload();
                this.show_status = false;
            }).catch(err => {
                alert(JSON.stringify(err));
            })
        },
        accept(){
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/accept',
                method : 'PUT',
                params : {action : 'accept'},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            })
        },

        start(){
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/start',
                method : 'PUT',
                params : {action : 'started'},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            })
        },

        pause(){
            if(confirm("Do you want to save your changes?")) {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/pause',
                    method : 'POST',
                    data : this.job,
                    params : {action : 'paused'},
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken,
                    }
                }).then(response => {
                    this.job = response.data;
                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                })
            }
            else {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/pause',
                    method : 'POST',
                    params : {action : 'paused'},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.job = response.data;

                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                })
            }
            
        },
        resume(){
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/resume',
                method : 'PUT',
                params : {action : 'resumed'},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            })
        },
        finish(){
            if(this.job.staff.id != this.user.id) {
                console.log(this.job, this.user);
                alert("You cannot complete a job that is not assigned to you.");
                return;
            }
            if(confirm("Are you sure you want to complete this job?")) {
                let formData = new FormData();
                if(this.job.region_sz) {
                    formData.append('region_sz', this.job.region_sz);
                }
                if(this.job.floor_basis) {
                    formData.append('floor_basis', this.job.floor_basis);
                }
                if(this.job.base_cooling_load) {
                    formData.append('base_cooling_load', this.job.base_cooling_load);
                }
                if(this.job.base_heating_load) {
                    formData.append('base_heating_load', this.job.base_heating_load);
                }
                if(this.job.plan_complexity) {
                    formData.append('plan_complexity', this.job.plan_complexity);
                }
                if(this.job.run_notes) {
                    formData.append('run_notes', this.job.run_notes);
                }
                
                formData.append('save_changes', 1);

                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/finish',
                    method : 'POST',
                    params : {action : 'finished'},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    },
                    data : formData
                }).then(response => {
                    this.job = response.data;

                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                    console.log(err);
                })
            }
            
        },
        reset() {
            if(confirm("This will reset the status of the job to on-going\nAre you sure you want to continue?")) {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/reset',
                    method : 'PUT',
                    data : {action : 'reset'},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    },
                }).then(response => {
                    this.job = response.data;
                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                    console.log(err);
                })
            }
        },
        removeFrateFile(id, idx) {
            if(confirm("Are you sure")) {
                axios({
                    url : this.$store.state.api_url + '/frates/' + id + '/delete',
                    method : 'POST',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.job.frates.splice(idx, 1);
                });
            }
        },
        viewFrateFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/frates/' + this.job.id + '/' + filename);
        },
        removeDbFile(id, idx) {
            if(confirm("Are you sure")) {
                axios({
                    url : this.$store.state.api_url + '/dbs/' + id + '/delete',
                    method : 'POST',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.job.dbs.splice(idx, 1);
                });
            }
        },
        viewDbFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/dbs/' + this.job.id + '/' + filename);
        },
        submitForChecking(){
            if(this.job.validator) {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/submit-for-checking',
                    method : 'PUT',
                    params : {action : 'submitted for checking'},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    },
                    data : {
                        validator : this.job.validator.id
                    }
                }).then(response => {
                    this.job = response.data;
                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            }
            else {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/checkers',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.staffs = response.data;
                    this.show_checkers = true;
                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            }
            
        },
        assignChecker(id) {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/submit-for-checking',
                method : 'PUT',
                params : {action : 'submitted for checking'},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                },
                data : {
                    validator : id
                }
            }).then(response => {
                this.job = response.data;
                this.show_checkers = false;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        editChecker() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/checkers',
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.staffs = response.data;
                this.show_checkers = true;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        assignJob() {
            this.$store.dispatch('loading', true);
            axios({
                url : process.env.VUE_APP_API_URL + '/staffs',
                method : 'GET',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.show_staffs = true;
                this.staffs = response.data.map(s => {
                    s.assigned_jobs_preview = s.assigned_jobs.map(j => {
                        return 'LBS Ref No. ' + j.lbs_ref_no + ' [' + j.deadline + ']' 
                    }).join("\n");

                    return s;
                });

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        reAssignJob() {
            this.$store.dispatch('loading', true);
            axios({
                url : process.env.VUE_APP_API_URL + '/staffs',
                method : 'GET',
                params : {exclude : this.job.staff.id},
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.show_staffs = true;
                this.staffs = response.data;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        assign(id) {
            this.$store.dispatch('loading', true);
            var action = this.job.job_status == 'allocated' ? 'assigned' : 're-assigned'
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs/' + this.job.id + '/assign',
                method : 'POST',
                data : {staff_id : id},
                params : {action : action},
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.reload();
                this.show_staffs = false;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        addFrate(type) {
            this.new_frate = {
                type : type,
                file : null
            };
        },
        saveFrate() {
            var data = new FormData();
            data.append('type', this.new_frate.type);
            data.append('file', this.new_frate.file);

            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/frates',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.frates.push(res.data.data);
                this.$store.state.loading = false;
                this.new_frate = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            });
        },
        deleteFrate(idx, id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/frates/' + id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.frates.splice(idx, 1);
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.$store.state.loading = false;
                });
            }
        },
        addDB(type) {
            this.new_db = {
                type : type,
                file : null
            };
        },
        saveDB() {
            var data = new FormData();
            data.append('type', this.new_db.type);
            data.append('file', this.new_db.file);

            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/dbs',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.dbs.push(res.data.data);
                this.$store.state.loading = false;
                this.new_db = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            });
        },
        deleteDB(idx, id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/dbs/' + id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.dbs.splice(idx, 1);
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.$store.state.loading = false;
                });
            }
        },
        addPrivateFile(type) {
            this.new_private_file = {
                type : type,
                file : null
            };
        },
        savePrivateFile() {
            var data = new FormData();
            data.append('type', this.new_private_file.type);
            data.append('file', this.new_private_file.file);

            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/private-files',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.private_files.push(res.data.data);
                this.$store.state.loading = false;
                this.new_private_file = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            });
        },
        deletePrivateFile(){
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/private-files/' + id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.private_files.splice(idx, 1);
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.$store.state.loading = false;
                });
            }
        },
        paginate(jump) {
            this.page += jump;
            this.offset = (this.page * 5) - 5;
        },
        updateComment(comment) {
            this.comment = comment;
        },
        postComment() {
            this.$store.commit('changeLoading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.$route.params.id + '/conversations',
                method : 'POST',
                data : {message : this.comment, job_id : this.$route.params.id},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$store.commit('changeLoading', false);
                this.comment_dialog = false;
                this.job.comments.unshift(res.data);
            }).catch(err => {
                this.$store.commit('changeLoading', false);
            })
        }
    }
}
</script>